<template>
  <div class="storehouses">
    <el-dialog :visible.sync="formDisplay">
      <div class="form-content">
        <h1 v-if="!currentStorehouse"> {{ $t('Add Storehouse') }} </h1>
        <h1 v-else> {{ $t('Edit Storehouse') }} </h1>
        <el-form ref="form" :model="form">
          <el-form-item :label="$t('Storehouse Name')" style="max-width: 80%">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Address')" style="max-width: 80%">
            <el-input v-model="form.address"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Phone')" style="max-width: 80%">
            <el-input v-model="form.phone"></el-input>
          </el-form-item>
        </el-form>
        <div class="submit-wrapper">
          <el-button class="submit-button" type="primary" @click="submit">{{ $t('Submit') }}</el-button>
        </div>
      </div>
    </el-dialog>

    <div class="page-header">
      <h1>{{ $t('Inventory Management') }} > {{ $t('Storehouses Management') }}</h1>
    </div>
    <div class="page-content">
      <div class="content-header">
        <h2>{{ $t('Storehouses Management') }}</h2>
        <el-button class="action-button" @click="showForm" type="primary">{{ $t('Add Storehouse') }}</el-button>
      </div>
      <div class="inventory-list">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">{{ $t('Storehouse Name') }}</th>
              <th scope="col">{{ $t('Address') }}</th>
              <th scope="col">{{ $t('Phone') }}</th>
              <th scope="col">{{ $t('Actions') }}</th>
            </tr>
          </thead>
          <tbody v-for="(storehouse) in storehouses">
            <tr>
              <td>{{ storehouse.name }}</td>
              <td> {{ storehouse.address }}</td>
              <td> {{ storehouse.phone }}</td>
              <td>
                <div class="action-links">
                  <a class="action-link" @click="editStorehouse(storehouse)"> {{ $t('Edit') }}</a>
                  <a class="action-link danger" @click="deleteStorehouse(storehouse.id)" href="#">{{ $t('Delete') }}</a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import StorehouseForm from '@/components/inventory/StorehouseForm.vue';
import Common from '@/lib/common';
import Inventory from '@/lib/inventory';

export default {
  name: 'Inventory',
  components: {
    StorehouseForm,
  },
  mounted() {
    this.loadStorehouses();
  },
  data() {
    return {
      storehouses: [],
      formDisplay: false,
      currentStorehouse: null,
      form: {
        name: '',
        address: '',
        phone: '',
      },
    }
  },
  methods: {
    showForm() {
      this.formDisplay = true;
    },
    async submit(){
      try {
        const loginInfo = Common.getLoginInfo();
        if(!this.currentStorehouse){
          await Inventory.createStorehouse(this.apiUrl, this.form, loginInfo);
          location.reload();
        }else{
          await Inventory.updateStorehouse(this.apiUrl, this.currentStorehouse.id, this.form, loginInfo);
          location.reload();
        }
      } catch (err) {
        console.log(err);
      }
    },
    async loadStorehouses() {
      try {
        const loginInfo = Common.getLoginInfo();
        const storehouses = await Inventory.loadAllStorehouses(this.apiUrl, loginInfo);
        this.storehouses = storehouses;
      } catch (err) {
        console.log(err);
      }
    },
    async editStorehouse(storehouse){
      this.currentStorehouse = storehouse;
      this.form.name = storehouse.name;
      this.form.address = storehouse.address;
      this.form.phone = storehouse.phone;
      this.formDisplay = true;
    },
    async deleteStorehouse(storehouseId) {
      try {
        const loginInfo = Common.getLoginInfo();
        await Inventory.deleteStorehouse(this.apiUrl, storehouseId, loginInfo);
        const newStorehouses = this.storehouses.filter(function (item) {
          return item.id !== storehouseId;
        });
        this.storehouses = newStorehouses;
      } catch (err) {
        console.log(err);
      }
    },
  },
  computed: mapState({
    langcode: state => state.langcode,
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style scoped lang="scss">
.content-header {
  display: flex;

  h2 {
    flex: 1;
  }

  .action-button {
    margin-right: 20px;
  }
}

.storehouse-image-wrapper {
  width: 75px;
  height: 75px;
  position: relative;
  overflow: hidden;

  .storehouse-image {
    overflow: hidden;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.storehouse-image-td {
  width: 20%;
}

.storehouse-quantity-editor {
  display: flex;

  .el-input,
  .form-control {
    max-width: 20%;
    margin-right: 50px;
  }
}
</style>